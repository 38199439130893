<template>
  <div class="zt-page-content">
    <div class="page-header" style="min-width: 1320px">企业数据</div>

    <div
      v-if="compCount && showBlock"
      class="zt-block block-top flex"
      style="min-width: 1300px"
    >
      <div class="bt-item flex flex-1" v-if="data1.show">
        <div class="left flex flex-center">
          <div style="text-align: center">
            <div class="left-i"><i class="iconfont icon-zhuce-shenhe"></i></div>
            <div style="margin-bottom: 4px">注册企业</div>
            <div style="color: #b3b3b3">
              <span>{{ compCount.reg.all }}/</span> 家
            </div>
          </div>
        </div>
        <div class="right flex-1">
          <template v-for="(comp, idx) in compCount.reg.type_group">
            <div :key="idx" v-if="comp.comp_type == 1 && data1.types['工厂']">
              <label>工厂</label>
              <span>{{ comp.count }}</span
              >家
            </div>
            <div
              :key="idx"
              v-if="comp.comp_type == 2 && data1.types['设计公司']"
            >
              <label v-if="comp.comp_type == 2">设计公司</label>
              <span>{{ comp.count }}</span
              >家
            </div>
            <div
              :key="idx"
              v-if="comp.comp_type == 3 && data1.types['销售公司']"
            >
              <label v-if="comp.comp_type == 3">销售公司</label>
              <span>{{ comp.count }}</span
              >家
            </div>
            <div :key="idx" v-if="comp.comp_type == 6 && data1.types['供应商']">
              <label v-if="comp.comp_type == 6">供应商</label>
              <span>{{ comp.count }}</span
              >家
            </div>
            <div
              :key="idx"
              v-if="comp.comp_type == 7 && data1.types['物流公司']"
            >
              <label v-if="comp.comp_type == 7">物流公司</label>
              <span>{{ comp.count }}</span
              >家
            </div>
            <div
              :key="idx"
              v-if="comp.comp_type == 8 && data1.types['金融服务公司']"
            >
              <label v-if="comp.comp_type == 8">金融服务公司</label>
              <span>{{ comp.count }}</span
              >家
            </div>
            <div
              :key="idx"
              v-if="comp.comp_type == 9 && data1.types['风控公司']"
            >
              <label v-if="comp.comp_type == 9">风控公司</label>
              <span>{{ comp.count }}</span
              >家
            </div>
          </template>
        </div>
      </div>
      <div class="bt-item flex flex-1" v-if="data2.show">
        <div class="left flex flex-center">
          <div style="text-align: center">
            <div class="left-i">
              <i style="font-size: 20px" class="iconfont icon-renzheng"></i>
            </div>
            <div style="margin-bottom: 4px">认证企业</div>
            <div style="color: #b3b3b3">
              <span>{{ compCount.cert.all }}/</span> 家
            </div>
          </div>
        </div>
        <div class="right flex-1">
          <template v-for="(comp, idx) in compCount.cert.type_group">
            <div :key="idx" v-if="comp.comp_type == 1 && data2.types['工厂']">
              <label>工厂</label>
              <span>{{ comp.count }}</span
              >家
            </div>
            <div
              :key="idx"
              v-if="comp.comp_type == 2 && data2.types['设计公司']"
            >
              <label v-if="comp.comp_type == 2">设计公司</label>
              <span>{{ comp.count }}</span
              >家
            </div>
            <div
              :key="idx"
              v-if="comp.comp_type == 3 && data2.types['销售公司']"
            >
              <label v-if="comp.comp_type == 3">销售公司</label>
              <span>{{ comp.count }}</span
              >家
            </div>
            <div :key="idx" v-if="comp.comp_type == 6 && data2.types['供应商']">
              <label v-if="comp.comp_type == 6">供应商</label>
              <span>{{ comp.count }}</span
              >家
            </div>
            <div
              :key="idx"
              v-if="comp.comp_type == 7 && data2.types['物流公司']"
            >
              <label v-if="comp.comp_type == 7">物流公司</label>
              <span>{{ comp.count }}</span
              >家
            </div>
            <div
              :key="idx"
              v-if="comp.comp_type == 8 && data2.types['金融服务公司']"
            >
              <label v-if="comp.comp_type == 8">金融服务公司</label>
              <span>{{ comp.count }}</span
              >家
            </div>
            <div
              :key="idx"
              v-if="comp.comp_type == 9 && data2.types['风控公司']"
            >
              <label v-if="comp.comp_type == 9">风控公司</label>
              <span>{{ comp.count }}</span
              >家
            </div>
          </template>
        </div>
      </div>
      <div class="bt-item flex flex-1" v-if="data3.show">
        <div class="left flex flex-center">
          <div style="text-align: center">
            <div class="left-i"><i class="iconfont icon-xinxiwanshan"></i></div>
            <div style="margin-bottom: 4px">
              资质完善
              <span style="font-size: 17px; font-weight: 600; color: #5074ee"
                >80%</span
              >
            </div>
            <div style="color: #b3b3b3">
              <span>{{ compCount.score.all }}/</span> 家
            </div>
          </div>
        </div>
        <div class="right flex-1">
          <template v-for="(comp, idx) in compCount.score.type_group">
            <div :key="idx" v-if="comp.comp_type == 1 && data3.types['工厂']">
              <label>工厂</label>
              <span>{{ comp.count }}</span
              >家
            </div>
            <div
              :key="idx"
              v-if="comp.comp_type == 2 && data3.types['设计公司']"
            >
              <label v-if="comp.comp_type == 2">设计公司</label>
              <span>{{ comp.count }}</span
              >家
            </div>
            <div
              :key="idx"
              v-if="comp.comp_type == 3 && data3.types['销售公司']"
            >
              <label v-if="comp.comp_type == 3">销售公司</label>
              <span>{{ comp.count }}</span
              >家
            </div>
            <div :key="idx" v-if="comp.comp_type == 6 && data3.types['供应商']">
              <label v-if="comp.comp_type == 6">供应商</label>
              <span>{{ comp.count }}</span
              >家
            </div>
            <div
              :key="idx"
              v-if="comp.comp_type == 7 && data3.types['物流公司']"
            >
              <label v-if="comp.comp_type == 7">物流公司</label>
              <span>{{ comp.count }}</span
              >家
            </div>
            <div
              :key="idx"
              v-if="comp.comp_type == 8 && data3.types['金融服务公司']"
            >
              <label v-if="comp.comp_type == 8">金融服务公司</label>
              <span>{{ comp.count }}</span
              >家
            </div>
            <div
              :key="idx"
              v-if="comp.comp_type == 9 && data3.types['风控公司']"
            >
              <label v-if="comp.comp_type == 9">风控公司</label>
              <span>{{ comp.count }}</span
              >家
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="zt-block tab flex" style="min-width: 1300px">
      <div style="width: 100%">
        <div class="flex-1">
          <div class="tab_menu">
            <div
              v-for="(item, idx) in computedTabList"
              :key="item.id"
              @click="tab(idx, item.id)"
              class="flex menu_item"
              :class="{ cur: tabIndex == idx }"
            >
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="false" class="flex" style="margin-top: 20px; width: 100%">
        <div class="flex-1">
          <label>入网状态：</label>
          <el-select
            size="small"
            style="margin-right: 10px; width: 150px"
            v-model="queryOpt.entStatus"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="已注册" :value="0"></el-option>
            <el-option label="已申请" :value="1"></el-option>
            <el-option label="已认证" :value="2"></el-option>
            <el-option label="未通过" :value="3"></el-option>
          </el-select>
          <label>注册日期：</label>
          <el-date-picker
            v-model="queryOpt.date"
            style="width: 280px; margin-right: 10px"
            size="small"
            type="daterange"
            align="right"
            unlink-panels
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>

          <el-input
            size="small"
            style="width: 180px; margin-right: 12px"
            v-model="queryOpt.name"
            placeholder="企业名称"
            clearable
          >
            <!-- <i slot="suffix" class="el-input__icon el-icon-search"></i> -->
          </el-input>
        </div>

        <div style="height: 32px">
          <el-button @click="search" type="primary" size="small"
            >查询</el-button
          >
          <el-button @click="toExport" type="primary" size="small"
            >导出</el-button
          >
        </div>
      </div>
    </div>
    <div class="zt-block" style="min-width: 1300px; min-height: 600px">
      <div class="list" style="height: calc(100% - 60px); overflow-y: auto">
        <el-table :data="list" style="width: 100%">
          <el-table-column prop="Id" label="企业ID" width="100">
          </el-table-column>
          <el-table-column
            prop="EntName"
            label="企业名称"
            min-width="200"
            v-if="showKey('企业名称')"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.EntName }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="CreatedAt"
            label="注册日期"
            v-if="showKey('注册日期')"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.CreatedAt | moment("YYYY/MM/DD") }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="CertStat"
            label="入网状态"
            v-if="showKey('入网状态')"
          >
            <template slot-scope="scope">
              <!-- style="color:#F66F6A;" style="color:#2BBAAE;" -->
              <div v-if="scope.row.CertStat == 0">已注册</div>
              <div v-if="scope.row.CertStat == 1">已申请</div>
              <div v-if="scope.row.CertStat == 2">已认证</div>
              <div v-if="scope.row.CertStat == 3">未通过</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="UserName"
            label="管理员姓名"
            v-if="showKey('管理员姓名')"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.UserName }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="Mobile"
            label="管理员手机号"
            v-if="showKey('管理员手机号')"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.Mobile }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="AptitudeScore"
            label="资质完善度"
            v-if="showKey('资质完善度')"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.AptitudeScore }}%</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="padding: 12px">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :current-page.sync="queryOpt.page"
          @current-change="currentChange"
          @size-change="currentChange"
          :page-size.sync="queryOpt.pagesize"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getEntCount,
  getEntConfig,
  setEntConfig,
  getList,
  exportEnt,
  toggleShow,
} from "@/service/company";
import { OTHER_API } from "@/config";
export default {
  data() {
    return {
      configDialog: {
        visible: false,
        id: "",
        companyTypes: [
          "工厂",
          "设计公司",
          "销售公司",
          "供应商",
          "物流公司",
          "金融服务公司",
          "风控公司",
        ],
        configTableDataOld: [],
        configTableData: [
          {
            name: "注册企业",
            show: true,
            types: {
              工厂: true,
              设计公司: true,
              销售公司: true,
              供应商: true,
              物流公司: true,
              金融服务公司: true,
              风控公司: true,
            },
          },
          {
            name: "认证企业",
            show: true,
            types: {
              工厂: true,
              设计公司: true,
              销售公司: true,
              供应商: true,
              物流公司: false,
              金融服务公司: false,
              风控公司: true,
            },
          },
          {
            name: "资质完善",
            show: true,
            types: {
              工厂: true,
              设计公司: true,
              销售公司: true,
              供应商: false,
              物流公司: false,
              金融服务公司: false,
              风控公司: false,
            },
          },
        ],
        keysOld: [],
        keys: [
          {
            name: "企业名称",
            show: true,
          },
          {
            name: "注册日期",
            show: true,
          },
          {
            name: "入网状态",
            show: true,
          },
          {
            name: "管理员姓名",
            show: true,
          },
          {
            name: "管理员手机号",
            show: true,
          },
          {
            name: "资质完善度",
            show: true,
          },
        ],
        showHidden: false,
      },
      popVisible: false,
      popTitle: null,
      tabList: [
        { id: 1, name: "工厂" },
        { id: 2, name: "设计公司" },
        { id: 3, name: "销售公司" },
        { id: 6, name: "供应商" },
        { id: 7, name: "物流公司" },
        { id: 8, name: "金融服务公司" },
        { id: 9, name: "风控公司" },
      ],
      tabIndex: 0,
      queryOpt: {
        name: "",
        date: [],
        showHidden: false,
        entType: 1,
        entStatus: "",
        page: 1,
        pagesize: 10,
      },
      total: 0,
      nplOptions: [
        { id: 20, name: "20" },
        { id: 100, name: "100" },
        { id: 200, name: "200" },
        { id: 99999, name: "不限" },
      ],
      list: [],
      entCount: null,
    };
  },
  async created() {
    await this.getConfig();
    this.refreshItems();
  },
  computed: {
    computedTabList() {
      if (!this.compCount) {
        return [];
      }
      return this.tabList.filter((n) => {
        const find = this.compCount.reg.type_group.find(
          (m) => m.comp_type === n.id
        );
        return find && this.data1.types[n.name] ? true : false;
      });
    },
    compCount() {
      const map = {
        FkCount: 9,
        GcCount: 1,
        GxCount: 4,
        GyCount: 6,
        JrCount: 8,
        SjCount: 2,
        WlCount: 7,
        XsCount: 3,
        ZfCount: 99,
      };
      const { entCount } = this;
      if (!entCount) {
        return null;
      }
      const compCount = {
        reg: {
          all: entCount.ZcEntityCount.AllCount,
          type_group: Object.keys(entCount.ZcEntityCount)
            .filter((n) => n !== "AllCount")
            .map((n) => {
              return {
                comp_type: map[n],
                count: entCount.ZcEntityCount[n],
              };
            }),
        },
        cert: {
          all: entCount.RzEntityCount.AllCount,
          type_group: Object.keys(entCount.RzEntityCount)
            .filter((n) => n !== "AllCount")
            .map((n) => {
              return {
                comp_type: map[n],
                count: entCount.RzEntityCount[n],
              };
            }),
        },
        score: {
          all: entCount.ZzWsCount.AllCount,
          type_group: Object.keys(entCount.ZzWsCount)
            .filter((n) => n !== "AllCount")
            .map((n) => {
              return {
                comp_type: map[n],
                count: entCount.ZzWsCount[n],
              };
            }),
        },
      };
      return compCount;
    },
    showBlock() {
      return this.configDialog.configTableData.filter((n) => n.show).length > 0;
    },
    data1() {
      return this.configDialog.configTableData.find(
        (n) => n.name === "注册企业"
      );
    },
    data2() {
      return this.configDialog.configTableData.find(
        (n) => n.name === "认证企业"
      );
    },
    data3() {
      return this.configDialog.configTableData.find(
        (n) => n.name === "资质完善"
      );
    },
    loginUser() {
      return this.$store.state.loginUser || {};
    },
  },
  methods: {
    showKey: function (key) {
      const find = this.configDialog.keys.find((n) => n.name === key);
      return find ? find.show : false;
    },
    async toggleShow(row) {
      const res = await toggleShow({
        id: row.Id + "",
        isConceal: 1 - row.IsConceal + "",
      });
      if (res.Success) {
        this.refreshItems();
      } else {
        this.$message.success("设置s失败！");
      }
    },
    async getConfig() {
      const data = { entId: "0" };
      const res = await getEntConfig(data);
      if (res.Success) {
        const data = JSON.parse(res.Content.EntConfigJson);
        console.log("config", data);
        this.configDialog.id = res.Content.Id;
        this.configDialog.showHidden = data.EntConfigModel.showHidden;
        this.configDialog.keys = data.EntConfigModel.EntConfigFields.map(
          (n) => {
            return {
              name: n.Name,
              key: n.Name,
              show: n.State === 1,
            };
          }
        );
        this.configDialog.keysOld = JSON.parse(
          JSON.stringify(this.configDialog.keys)
        );

        this.configDialog.configTableData =
          data.EntConfigModel.EntConfigStates.map((n) => {
            const types = {};
            n.EntConfigTypes.map((m) => {
              types[m.Typename] = m.Entstate === 1;
            });
            return {
              name: n.EntConfigStateName,
              show: n.EntConfigStates === "1",
              types,
            };
          });
        this.configDialog.configTableDataOld = JSON.parse(
          JSON.stringify(this.configDialog.configTableData)
        );
      }
    },
    async saveConfig() {
      const data = {
        id: this.configDialog.id,
        entId: "0",
      };
      const EntConfigStates = this.configDialog.configTableData.map((n) => {
        return {
          EntConfigStateName: n.name,
          EntConfigStates: n.show ? "1" : "0",
          EntConfigTypes: this.configDialog.companyTypes.map((m) => {
            return {
              Typename: m,
              Entstate: n.types[m] ? 1 : 0,
            };
          }),
        };
      });
      const EntConfigFields = this.configDialog.keys.map((n) => {
        return {
          Name: n.name,
          State: n.show ? 1 : 0,
        };
      });
      const entConfigJson = {
        EntConfigModel: {
          EntConfigFields,
          EntConfigStates,
        },
      };
      data.entConfigJson = JSON.stringify(entConfigJson);
      console.log(entConfigJson);
      const res = await setEntConfig(data);
      if (res.Success) {
        this.$message.success("保存成功！");
        this.closeDialog();
      } else {
        this.$message.error("保存失败！");
      }
    },
    cancel() {
      this.configDialog.configTableData = this.configDialog.configTableDataOld;
      this.configDialog.keys = this.configDialog.keysOld;
      this.closeDialog();
    },
    showConfigDialog() {
      this.configDialog.visible = true;
      this.getConfig();
    },
    closeDialog() {
      this.configDialog.visible = false;
    },
    goto(link) {
      this.$router.push(link);
    },
    // 选项卡
    tab(index, id) {
      this.tabIndex = index;
      this.queryOpt.entType = id;
      this.queryOpt.page = 1;
      this.refreshItems();
    },
    search() {
      this.queryOpt.page = 1;
      this.refreshItems();
    },
    async toExport() {
      const { name, entType, entStatus, date } = this.queryOpt;
      const data = {
        entName: name,
        entId: "0",
      };
      if (entType !== "") {
        data.entType = entType;
      }
      if (entStatus !== "") {
        data.entStatus = entStatus;
      }
      if (!this.configDialog.showHidden) {
        data.isConceal = 0;
      }
      if (date && date.length > 0) {
        data.crAt = date[0];
        data.endAt = date[1];
      }

      let url = `${OTHER_API}/CenterCompanyAPI/ExportEntBy`;
      for (let key in data) {
        if (url.indexOf("?") === -1) {
          url += "?";
        } else {
          url += "&";
        }
        if (data[key] !== undefined) {
          url += `${key}=${data[key]}`;
        }
      }
      window.open(url);
    },
    async refreshItems() {
      console.log("refreshItems");
      let obj = {};
      if (!this.configDialog.showHidden) {
        obj.isConceal = "0";
      }
      const res = await getEntCount(obj);
      console.log(res);
      if (res.Success) {
        this.entCount = res.Content;
      }
      const { pagesize, page, name, entType, entStatus, date } = this.queryOpt;
      const data = {
        pageSize: pagesize,
        pageIndex: page,
        entName: name,
      };
      if (entType !== "") {
        data.entType = entType;
      }
      if (entStatus !== "") {
        data.entStatus = entStatus;
      }
      if (!this.configDialog.showHidden) {
        data.isConceal = 0;
      }
      if (date && date.length > 0) {
        data.crAt = date[0];
        data.endAt = date[1];
      }
      const rst = await getList(data);
      if (rst.Success) {
        this.total = rst.Content.RecordCount;
        this.list = rst.Content.Datas;
      }
    },
    currentChange(e) {
      this.refreshItems();
    },
  },
};
</script>
<style scoped src="../style.css"></style>
<style lang="less">
.dialog-tit {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
.dialog-block {
  background: #fff;
  .keys {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    label {
      color: #999;
      font-weight: bold;
    }
  }
}
.zt-block {
  position: relative;
}
.block-top {
  box-shadow: none;
  background: transparent;
  .bt-item {
    border-left: 4px solid #5074ee;
    background: #fff;
    padding: 20px;
    margin-right: 30px;
    font-size: 14px;
    color: #4d4d4d;
    .left {
      width: 140px;
      border-right: 1px solid #e6e6e6;
      span {
        font-size: 20px;
        color: #292929;
      }
      .left-i {
        margin-bottom: 8px;
        i {
          font-size: 17px;
          color: #5074ee;
          padding: 7px;
          background: #e7ebff;
          border-radius: 50%;
        }
      }
    }
    .right {
      line-height: 30px;
      padding-left: 30px;
      > div {
        color: #b3b3b3;
      }
      label {
        display: inline-block;
        width: 90px;
        text-align: right;
      }
      span {
        font-size: 20px;
        color: #292929;
        margin: 0 4px 0 8px;
      }
    }
  }
  .bt-item:last-child {
    margin-right: 0;
  }
}
</style>
<style scoped>
div >>> .el-table__cell {
  border-right: 1px solid #ebeef5;
}

.tab {
  box-shadow: none;
  background: transparent;
}
.tab_menu .menu_item {
  display: inline-block;
  vertical-align: top;
  width: 86px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  background: #e6e6e6;
  margin-right: 20px;
  cursor: pointer;
  font-size: 14px;
  color: #808080;
}
.tab_menu .menu_item.cur {
  color: #fff;
  background: #5074ee;
}

div >>> .el-table .cell {
  text-align: center;
}
div >>> .el-dialog {
  min-width: 550px;
}
div >>> .el-dialog__body {
  background: #f5f5f5;
}
</style>
