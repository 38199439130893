import { render, staticRenderFns } from "./CompData.vue?vue&type=template&id=5d0dd556&scoped=true&"
import script from "./CompData.vue?vue&type=script&lang=js&"
export * from "./CompData.vue?vue&type=script&lang=js&"
import style0 from "../style.css?vue&type=style&index=0&id=5d0dd556&prod&scoped=true&lang=css&"
import style1 from "./CompData.vue?vue&type=style&index=1&id=5d0dd556&prod&lang=less&"
import style2 from "./CompData.vue?vue&type=style&index=2&id=5d0dd556&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d0dd556",
  null
  
)

export default component.exports