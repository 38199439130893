import { postToBaseInfo1Server, postToFactoryServer, postToOtherServer,getToOtherServer } from './common';


export async function getEntConfig(obj) {
    return await postToOtherServer('/CenterCompanyAPI/GetEntConfig', obj);
}

export async function setEntConfig(obj) {
    return await postToOtherServer('/CenterCompanyAPI/InsertEntConfig', obj);
}

export async function getEntCount(obj) {
    return await postToOtherServer('/CenterCompanyAPI/GetEntCount', obj);
}

export async function getList(obj) {
    return await postToOtherServer('/CenterCompanyAPI/PageingEnt', obj);
}

export async function toggleShow(obj) {
    return await postToOtherServer('/CenterCompanyAPI/UptEnt', obj);
}
/**
 * 企业列表
 */
export async function compIndex(obj){
    return await postToBaseInfo1Server('/comp/index',obj);
}
/**
 * 企业注册统计
 */
export async function compCount(obj){
    return await postToBaseInfo1Server('/comp/count',obj);
}
// 批量设置企业类型为规上企业
 export async function doInSpecFactory(obj){
    return await postToFactoryServer('/factory/doInSpec-factory',obj);
}
// 批量设置企业类型为非规上企业
export async function unDoInSpecFactory(obj){
    return await postToFactoryServer('/factory/unDoInSpec-factory',obj);
}
